import React from "react"
import Layout from "../../components/Layout"

const AboutPage = () => (
  <Layout>
    <h1>About Page</h1>
  </Layout>
)

export default AboutPage
